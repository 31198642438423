import { AddScraperModalPayload, EditScraperModalPayload } from 'components/admin';
import { ScraperStats, ScraperUser } from 'models';
import axiosClient from './axios-client';

export const scraperUserApi = {
  getAll: async (): Promise<ScraperUser[]> => {
    const response = await axiosClient.get('/list-scraper');
    return response.data ?? response;
  },

  get: async (): Promise<ScraperUser> => {
    const response = await axiosClient.get('/scraper-user');
    return response.data ?? response;
  },

  getStats: async (): Promise<ScraperStats> => {
    const response = await axiosClient.get('/job-completed');
    return response.data ?? response;
  },

  add: (payload: AddScraperModalPayload) => {
    return axiosClient.post('/scraper', payload);
  },

  update: (payload: EditScraperModalPayload) => {
    return axiosClient.patch(`/scraper/${payload.slug}`, payload);
  },

  delete: (slug: string) => {
    return axiosClient.delete(`/scraper/${slug}`);
  },

  grantUrl: (slug: string, amount: number, type: string) => {
    return axiosClient.get(`/grant-url/${slug}/${type}`, { params: { amount } });
  },
};
