export const ROUTES = [
  {
    path: '/dashboard',
    label: 'Dashboard',
    disabled: true,
  },
  {
    path: '/working-area',
    label: 'Working area',
  },
  {
    path: '/payment',
    label: 'Payment',
    disabled: true,
  },
  {
    path: '/admin',
    label: 'Admin',
  },
  {
    path: '/analytics',
    label: 'Analytics',
    disabled: true,
  },
  {
    path: '/settings',
    label: 'Settings',
    disabled: true,
  },
];
