import { createTheme } from '@mui/material/styles';

// Create a theme instance.
export const theme = createTheme({
  typography: {
    fontFamily: 'ploni',
  },

  palette: {
    primary: {
      main: '#307FF6',
    },

    text: {
      primary: '#404040',
    },
  },

  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: 'xl',
      },
    },

    MuiButton: {
      defaultProps: {
        // color: 'inherit',
        disableElevation: true,
      },

      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '60px',
        },

        sizeMedium: {
          fontSize: '16px',
          paddingLeft: '20px',
          paddingRight: '20px',
        },

        contained: {
          color: '#fff',
        },

        outlined: {
          color: '#00074F',
          borderColor: '#00074F',
        },
      },
    },

    MuiLink: {
      defaultProps: {
        color: 'inherit',
      },
      styleOverrides: {
        root: {
          textDecoration: 'none',

          '&:hover, &.active': {
            color: '#307FF6',
            textDecoration: 'none',
          },
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 8px 12px 0px rgba(0, 0, 0, 0.15)',
          borderRadius: '20px',
        },
      },
    },
  },
});
