import { User, signInWithEmailAndPassword } from 'firebase/auth';
import { LoginPayload } from 'models';
import { toast } from 'react-toastify';
import { auth } from 'utils';
import { create } from 'zustand';

// firebase auth

export interface AuthState {
  authUser: User | null;
  authLoading: boolean;

  updateAuthUser: (authUser: User | null) => void;
  onLogin: (payload: LoginPayload) => void;
  onLogout: () => void;
}

export const useAuth = create<AuthState>((set) => ({
  authUser: null,
  authLoading: true,

  updateAuthUser: (authUser) => set(() => ({ authUser, authLoading: false })),

  onLogin: (payload: LoginPayload) => {
    const { email, password } = payload;

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        toast.success('Login successfully');
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error(errorMessage);
      });
  },

  onLogout: () => {
    auth
      .signOut()
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  },
}));
