import { Menu } from '@brightsource/brightsource-ui-lib';
import { Box, Container, IconButton, Link as MuiLink, Stack } from '@mui/material';
import { BellIcon, EthosiaLogo, PlusIcon, SearchIcon, UserIcon } from 'assets/icons';
import { useAuth, useCurrentUser } from 'hooks';
import { Link, NavLink } from 'react-router-dom';
import { ROUTES } from './routes';

function HeaderDesktop() {
  const { authUser, authLoading, onLogout } = useAuth();
  const { isAdmin } = useCurrentUser();

  const onClickExtension = () => {
    window.open(
      'https://chromewebstore.google.com/detail/brightsource-auto/mncnidooeaoangclkjkdimigolmmghod',
      '_blank',
    );
  };

  // if (loading) return <h1>Loading...</h1>;

  return (
    <Box
      component="header"
      py={4}
      bgcolor="#fff"
      boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.25)"
      display={{ xs: 'none', md: 'block' }}
    >
      <Container>
        <Stack direction="row" spacing={5} alignItems="center">
          <Link to="/" style={{ display: 'flex' }}>
            <EthosiaLogo />
          </Link>

          <Stack direction="row" spacing={5} flex={1}>
            {authUser &&
              ROUTES.map((route) => {
                if (route.path === '/admin' && !isAdmin) return null;

                return (
                  <MuiLink
                    key={route.path}
                    component={NavLink}
                    to={route.path}
                    fontSize="1.125rem"
                    fontWeight={500}
                    className={route.disabled ? 'disabled' : ''}
                    sx={{
                      '&.disabled': {
                        color: 'action.disabled',
                        cursor: 'default',
                        pointerEvents: 'none',
                      },
                    }}
                  >
                    {route.label}
                  </MuiLink>
                );
              })}
          </Stack>

          <Stack direction="row" spacing={2} alignItems="center">
            <IconButton>
              <SearchIcon />
            </IconButton>

            <IconButton>
              <PlusIcon />
            </IconButton>

            <IconButton>
              <BellIcon />
            </IconButton>

            {authUser && (
              <Menu
                items={[
                  ...(authUser.email ? [{ text: authUser.email }] : []),
                  { text: 'Chrome Autoscrape Extension', onClick: onClickExtension },
                  { text: 'Logout', onClick: onLogout },
                ]}
                showAsIcon
              >
                <UserIcon />
              </Menu>
            )}
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}

export default HeaderDesktop;
