import { useQuery } from '@tanstack/react-query';
import { userApi } from 'api';
import { QueryKeys } from 'constants/query-keys';
import { useAuth } from './use-auth';

export function useCurrentUser() {
  const { authUser } = useAuth();

  const { data: currentUser, error } = useQuery({
    // ...options,
    enabled: !!authUser,
    queryKey: [QueryKeys.GET_CURRENT_USER],
    queryFn: userApi.getCurrentUser,
    select: (data) => data.user,
  });

  const firstLoading = currentUser === undefined && error === null;
  const isInvalid = currentUser && (!currentUser.canSignIn || !currentUser.isActivated || currentUser.isDeleted);

  return {
    currentUser,
    firstLoading,
    isLoggedIn: Boolean(currentUser),

    isInvalid,
    isScraper: !isInvalid && currentUser?.userType === 4,
    isAdmin: currentUser?.userType === 3 && currentUser?.permission === 5,
  };
}
