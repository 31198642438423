import { FilterParams, ScraperJob } from 'models';
import axiosClient from './axios-client';

export const scraperJobApi = {
  getAll: async (params: FilterParams): Promise<ScraperJob[]> => {
    const response = await axiosClient.get('/scrape-linkedin-url', { params });
    return response.data ?? response;
  },

  delete: async (id: string) => {
    return axiosClient.post('/invalid-url', { id });
  },
};

// scraper user
// router.get('/api/user', _userController.getCurrentUser);
// router.get('/api/scraper-user', _userController.getCurrentScraperUser)
// router.get('/api/scrape-linkedin-url', _linkedinURLController.getListScrapeLinkedinURL)

// admin
// router.get('/api/grant-url/:userSlug', _linkedinURLController.grantURLToUser);
// router.post('/api/scraper', _userController.createScraperUser);
// router.patch('/api/scraper/:slug', _userController.updateScraperUser);
// router.delete('/api/scraper/:slug', _userController.deleteScraperUser);
// router.get('/api/list-scraper', _userController.getListScraperUser);
