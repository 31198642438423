import { useQuery } from '@tanstack/react-query';
import { scraperUserApi } from 'api';
import { QueryKeys } from 'constants/query-keys';
import { useCurrentUser } from './use-current-user';

export function useScraperList() {
  const { isAdmin } = useCurrentUser();

  return useQuery({
    // ...options,
    enabled: isAdmin,
    queryKey: [QueryKeys.GET_SCRAPER_LIST],
    queryFn: scraperUserApi.getAll,
  });
}
