import HeaderDesktop from './header-desktop';
import HeaderMobile from './header-mobile';

function Header() {
  return (
    <>
      <HeaderMobile />
      <HeaderDesktop />
    </>
  );
}

export default Header;
