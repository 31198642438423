import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { Box, Container, Stack } from '@mui/material';
import { EthosiaLogoMobile, SearchIcon, UserIcon } from 'assets/icons';
import { Link } from 'react-router-dom';

function HeaderMobile() {
  return (
    <Box
      component="header"
      py={2}
      bgcolor="#fff"
      boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.25)"
      display={{ xs: 'block', md: 'none' }}
    >
      <Container>
        <Stack direction="row" spacing={3} alignItems="center">
          <MenuRoundedIcon />

          <Link to="/" style={{ display: 'flex' }}>
            <EthosiaLogoMobile />
          </Link>

          <Box flexGrow={1} />

          <Stack direction="row" spacing={3} alignItems="center">
            <SearchIcon />
            <UserIcon />
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}

export default HeaderMobile;
