import { UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { scraperJobApi, scraperUserApi } from 'api';
import { QueryKeys } from 'constants/query-keys';
import { FilterParams, ScraperUser } from 'models';
import { useCurrentUser } from './use-current-user';

const REFETCH_INTERVAL = 3000;

export function useScraperUser(options?: UseQueryOptions<ScraperUser>) {
  const { isScraper } = useCurrentUser();

  return useQuery({
    // ...options,
    enabled: isScraper,
    queryKey: [QueryKeys.GET_SCRAPER_USER],
    queryFn: scraperUserApi.get,
    refetchInterval: REFETCH_INTERVAL,
  });
}

export function useScraperJobs(params: FilterParams) {
  const { isScraper } = useCurrentUser();

  return useQuery({
    enabled: isScraper,
    queryKey: [QueryKeys.GET_SCRAPER_JOBS, params],
    queryFn: () => scraperJobApi.getAll(params),
    refetchInterval: REFETCH_INTERVAL,
  });
}

export function useScraperStats() {
  const { isScraper } = useCurrentUser();

  return useQuery({
    enabled: isScraper,
    queryKey: [QueryKeys.GET_SCRAPER_STATS],
    queryFn: scraperUserApi.getStats,
    refetchInterval: REFETCH_INTERVAL,
  });
}

export function useScraperAdd(onSuccess?: () => void) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: scraperUserApi.add,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [QueryKeys.GET_SCRAPER_LIST] });
      onSuccess && onSuccess();
    },
  });
}

export function useScraperEdit(onSuccess?: () => void) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: scraperUserApi.update,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.GET_SCRAPER_LIST] });
      onSuccess && onSuccess();
    },
  });
}

export function useScraperDelete(onSuccess?: () => void) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: scraperUserApi.delete,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.GET_SCRAPER_LIST] });
      onSuccess && onSuccess();
    },
  });
}
