import { Loader } from '@brightsource/brightsource-ui-lib';
import { Box, Stack } from '@mui/material';
import { useAuth, useCurrentUser } from 'hooks';
import { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { auth } from 'utils';
import Header from './components/header';

const LoginPage = lazy(() => import('./pages/login'));
const AdminPage = lazy(() => import('./pages/admin'));
const WorkingAreaPage = lazy(() => import('./pages/working-area'));

const PrivateRoutes = () => {
  const { authUser, authLoading, onLogout } = useAuth();

  const { currentUser, isInvalid } = useCurrentUser();
  console.log({ currentUser });

  if (isInvalid) {
    onLogout();
  }

  if (authLoading) return <Loader />;

  if (authUser) return <Outlet />;

  return <Navigate to="/login" replace />;
};

function App() {
  const { updateAuthUser } = useAuth();

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => updateAuthUser(user));

    return () => unregisterAuthObserver();
  }, [updateAuthUser]);

  return (
    <Stack minHeight="100vh" bgcolor="#F8F8F8">
      <Suspense fallback={<div>Loading ...</div>}>
        <BrowserRouter>
          <Header />

          <Box component="main" flexGrow={1}>
            <Routes>
              <Route index element={<Navigate to="/working-area" replace />} />

              <Route element={<PrivateRoutes />}>
                <Route path="/admin" element={<AdminPage />} />
                <Route path="/working-area" element={<WorkingAreaPage />} />
              </Route>

              <Route path="/login" element={<LoginPage />} />
              <Route path="*" element={<Box>Not Found</Box>} />
            </Routes>
          </Box>

          <footer></footer>
        </BrowserRouter>
      </Suspense>
    </Stack>
  );
}

export default App;
