import { MouseEventHandler } from 'react';
import { ReactComponent as EthosiaLogoMobile } from './ethosia-mobile.svg';
import { ReactComponent as EthosiaLogo } from './ethosia.svg';

const SearchIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM10.733 6.71429C8.51354 6.71429 6.71429 8.51354 6.71429 10.733C6.71429 12.9525 8.51354 14.7518 10.733 14.7518C12.9525 14.7518 14.7518 12.9525 14.7518 10.733C14.7518 8.51354 12.9525 6.71429 10.733 6.71429ZM5 10.733C5 7.56676 7.56676 5 10.733 5C13.8993 5 16.4661 7.56676 16.4661 10.733C16.4661 12.007 16.0505 13.184 15.3475 14.1357L18.4648 17.253C18.7995 17.5877 18.7995 18.1305 18.4648 18.4652C18.13 18.7999 17.5873 18.7999 17.2526 18.4652L14.1353 15.3479C13.1836 16.0506 12.0068 16.4661 10.733 16.4661C7.56676 16.4661 5 13.8993 5 10.733Z" fill="#00074F"/>
</svg>

const UserIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M20.4853 3.51471C18.2188 1.24823 15.2053 0 12 0C8.79474 0 5.78119 1.24823 3.51471 3.51471C1.24823 5.78119 0 8.79474 0 12C0 15.2053 1.24823 18.2188 3.51471 20.4853C5.78119 22.7518 8.79474 24 12 24C15.2053 24 18.2188 22.7518 20.4853 20.4853C22.7518 18.2188 24 15.2053 24 12C24 8.79474 22.7518 5.78119 20.4853 3.51471ZM5.20807 20.1233C5.60431 16.7139 8.53564 14.0815 12 14.0815C13.8263 14.0815 15.5436 14.7931 16.8354 16.0847C17.9268 17.1762 18.6151 18.6013 18.7921 20.1231C16.9519 21.6643 14.5825 22.5938 12 22.5938C9.41748 22.5938 7.04828 21.6645 5.20807 20.1233ZM12 12.6332C9.99042 12.6332 8.35529 10.998 8.35529 8.98846C8.35529 6.9787 9.99042 5.34375 12 5.34375C14.0096 5.34375 15.6447 6.9787 15.6447 8.98846C15.6447 10.998 14.0096 12.6332 12 12.6332ZM20.0024 18.9344C19.6441 17.4897 18.8969 16.1576 17.8297 15.0905C16.9655 14.2262 15.943 13.5778 14.8279 13.1715C16.1684 12.2624 17.051 10.7265 17.051 8.98846C17.051 6.20343 14.785 3.9375 12 3.9375C9.21497 3.9375 6.94904 6.20343 6.94904 8.98846C6.94904 10.7274 7.83234 12.2639 9.17395 13.1728C8.14801 13.5467 7.19861 14.1248 6.38177 14.8857C5.19891 15.9873 4.3775 17.3919 3.99664 18.9333C2.38367 17.0739 1.40625 14.649 1.40625 12C1.40625 6.15857 6.15857 1.40625 12 1.40625C17.8414 1.40625 22.5938 6.15857 22.5938 12C22.5938 14.6495 21.616 17.075 20.0024 18.9344Z" fill="#00074F"/>
</svg>

const PlusIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M12.9999 7.4288C12.9999 6.87651 12.5522 6.4288 11.9999 6.4288C11.4476 6.4288 10.9999 6.87652 10.9999 7.4288V11.0001H7.42953C6.87725 11.0001 6.42953 11.4478 6.42953 12.0001C6.42953 12.5524 6.87725 13.0001 7.42953 13.0001H10.9999V16.5717C10.9999 17.1239 11.4476 17.5717 11.9999 17.5717C12.5522 17.5717 12.9999 17.1239 12.9999 16.5717V13.0001H16.5724C17.1247 13.0001 17.5724 12.5524 17.5724 12.0001C17.5724 11.4478 17.1247 11.0001 16.5724 11.0001H12.9999V7.4288Z" fill="#A4A4A4"/>
<circle cx="12" cy="12" r="11" stroke="#A4A4A4" strokeWidth="2"/>
</svg>

const BellIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none">
<mask id="mask0_2929_5679" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="9" y="14" width="12" height="10">
  <rect width="10.2952" height="4.36419" transform="matrix(0.858403 -0.512976 0.487136 0.873326 9.29907 20.1885)" fill="#C4C4C4"/>
</mask>
<g mask="url(#mask0_2929_5679)">
  <path fillRule="evenodd" clipRule="evenodd" d="M14.6318 20.0668C15.6175 19.4777 15.9947 18.1397 15.3899 17.0554C14.785 15.971 13.477 15.6402 12.4912 16.2293C11.5054 16.8184 11.1282 18.1563 11.7331 19.2407C12.3379 20.325 13.646 20.6559 14.6318 20.0668ZM15.4748 21.5783C17.3051 20.4845 17.9322 18.062 16.8755 16.1676C15.8188 14.2731 13.4784 13.624 11.6481 14.7178C9.81785 15.8116 9.19075 18.234 10.2475 20.1285C11.3042 22.0229 13.6446 22.672 15.4748 21.5783Z" fill="#A4A4A4"/>
</g>
<path fillRule="evenodd" clipRule="evenodd" d="M6.62902 0.441296C6.39419 0.0203048 5.87411 -0.123938 5.46737 0.119122C5.06064 0.362182 4.92129 0.900501 5.15611 1.32149L6.03204 2.89183C3.70928 4.69496 2.66203 7.82023 3.47649 10.7672L5.28009 17.2932C6.04086 20.046 9.0984 21.3258 11.494 19.8942L19.0001 15.4086C21.3956 13.977 21.8536 10.5964 19.9308 8.53806L15.3724 3.65833C13.3138 1.45465 10.175 0.830806 7.50491 2.01159L6.62902 0.441296ZM14.1497 4.882L18.7081 9.76173C19.8618 10.9967 19.587 13.0251 18.1497 13.8841L10.6436 18.3696C9.20625 19.2286 7.37173 18.4607 6.91526 16.8091L5.11166 10.2831C4.43286 7.82693 5.45614 5.20781 7.59359 3.93049C9.73103 2.65316 12.4341 3.04547 14.1497 4.882ZM16.3234 1.06827C16.0812 1.48476 16.211 2.02562 16.6134 2.27632C17.3451 2.73219 18.0146 3.29058 18.6022 3.93702C19.1876 4.58106 19.6838 5.30372 20.0782 6.08409C20.2508 6.42578 20.404 6.77854 20.5365 7.1406C20.703 7.59518 21.1939 7.82404 21.6331 7.65177C22.0723 7.47951 22.2934 6.97136 22.1269 6.51678C21.9703 6.08889 21.7893 5.67199 21.5852 5.26817C21.1191 4.34592 20.5327 3.49186 19.8409 2.73072C19.1464 1.96676 18.3553 1.30684 17.4905 0.768082C17.0881 0.517385 16.5656 0.651786 16.3234 1.06827ZM1.70423 11.1752C1.69571 10.6914 1.30647 10.3122 0.834845 10.3281C0.363221 10.3441 -0.0121943 10.7492 -0.00366849 11.233C0.014719 12.2764 0.171097 13.314 0.46256 14.3167C0.752942 15.3157 1.17351 16.2665 1.71105 17.1436C1.94643 17.5277 2.20425 17.8977 2.48341 18.2514C2.77811 18.6248 3.31558 18.6778 3.6839 18.3698C4.05221 18.0618 4.11188 17.5094 3.81718 17.136C3.58 16.8354 3.36093 16.5211 3.16091 16.1947C2.70408 15.4493 2.34674 14.6413 2.10007 13.7927C1.85248 12.941 1.71983 12.0602 1.70423 11.1752Z" fill="#A4A4A4"/>
</svg>

const OpenInNewIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
<g clipPath="url(#clip0_17050_184)">
  <path d="M14.3973 8.45049H13.2673C13.0333 8.45049 12.8435 8.64023 12.8435 8.87426V14.0264C12.8435 14.2999 12.621 14.5224 12.3475 14.5224H2.47348C2.20007 14.5224 1.97768 14.2999 1.97768 14.0264V4.15253C1.97768 3.87901 2.20007 3.65645 2.47348 3.65645H7.88944C8.12346 3.65645 8.3132 3.46672 8.3132 3.23269V2.10266C8.3132 1.86863 8.12346 1.67889 7.88944 1.67889H2.47348C1.10964 1.67889 0.00012207 2.78858 0.00012207 4.15253V14.0265C0.00012207 15.3904 1.1097 16.5 2.47348 16.5H12.3475C13.7114 16.5 14.821 15.3904 14.821 14.0265V8.87431C14.8211 8.64023 14.6314 8.45049 14.3973 8.45049Z" fill="#A4A4A4"/>
  <path d="M15.5762 0.5H11.0814C10.8473 0.5 10.6576 0.689732 10.6576 0.923762V2.05379C10.6576 2.28782 10.8473 2.47755 11.0814 2.47755H12.6241L6.81882 8.28269C6.65333 8.44819 6.65333 8.71646 6.81882 8.88201L7.61787 9.68111C7.69736 9.76061 7.80511 9.80524 7.91755 9.80524C8.02993 9.80524 8.13774 9.76061 8.21718 9.68111L14.0224 3.87586V5.41852C14.0224 5.65255 14.2122 5.84228 14.4462 5.84228H15.5762C15.8103 5.84228 16 5.65255 16 5.41852V0.923762C16 0.689732 15.8103 0.5 15.5762 0.5Z" fill="#A4A4A4"/>
</g>
<defs>
  <clipPath id="clip0_17050_184">
    <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
  </clipPath>
</defs>
</svg>

const MenuIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M6 9C6 9.82843 5.32843 10.5 4.5 10.5C3.67157 10.5 3 9.82843 3 9C3 8.17157 3.67157 7.5 4.5 7.5C5.32843 7.5 6 8.17157 6 9ZM10.5 9C10.5 9.82843 9.82843 10.5 9 10.5C8.17157 10.5 7.5 9.82843 7.5 9C7.5 8.17157 8.17157 7.5 9 7.5C9.82843 7.5 10.5 8.17157 10.5 9ZM13.5 10.5C14.3284 10.5 15 9.82843 15 9C15 8.17157 14.3284 7.5 13.5 7.5C12.6716 7.5 12 8.17157 12 9C12 9.82843 12.6716 10.5 13.5 10.5Z" fill="#A4A4A4"/>
</svg>

const TrashIcon = ({ onClick }: { onClick?: MouseEventHandler<SVGSVGElement> }) => <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M5.98507 2.655L5.82477 3.60951C5.12172 3.64768 4.42005 3.70142 3.72 3.77248L2.19 3.92248C1.8825 3.95248 1.6575 4.22248 1.6875 4.53748C1.7175 4.85248 1.995 5.07748 2.31 5.04748L3.84 4.89748C7.7625 4.49998 11.7225 4.64998 15.69 5.04748H15.75C16.035 5.04748 16.2825 4.82998 16.305 4.53748C16.335 4.22998 16.11 3.95248 15.8025 3.92248C14.5941 3.80388 13.3863 3.70664 12.1804 3.63933L12.1801 3.6375L12.0151 2.6625L12.0143 2.6581C11.9019 1.96811 11.7338 0.9375 9.98257 0.9375H8.01757C6.27132 0.9375 6.1053 1.93358 5.98533 2.65346L5.98507 2.655ZM10.9126 2.85L11.0369 3.58473C9.67969 3.5312 8.32532 3.5191 6.9755 3.5607L7.10257 2.8425L7.10371 2.83575C7.21518 2.17429 7.23275 2.07 8.02507 2.07H9.99007C10.7851 2.07 10.8076 2.1975 10.9126 2.85ZM6.59248 17.0626H11.4075C14.025 17.0626 14.13 15.6151 14.2125 14.4451L14.7 6.89256C14.7225 6.57756 14.4825 6.31506 14.175 6.29256C13.8675 6.27756 13.5975 6.51006 13.575 6.81756L13.0875 14.3701C13.0125 15.5101 12.9825 15.9376 11.4075 15.9376H6.59248C5.02592 15.9376 4.99502 15.5106 4.91263 14.3721L4.91249 14.3701L4.42499 6.81756C4.40249 6.51006 4.13998 6.27756 3.82499 6.29256C3.51749 6.31506 3.27749 6.58506 3.29999 6.89256L3.78749 14.4451C3.86999 15.6151 3.97498 17.0626 6.59248 17.0626ZM10.2451 12.9375H7.74762C7.44012 12.9375 7.18512 12.6825 7.18512 12.375C7.18512 12.0675 7.44012 11.8125 7.74762 11.8125H10.2451C10.5526 11.8125 10.8076 12.0675 10.8076 12.375C10.8076 12.6825 10.5526 12.9375 10.2451 12.9375ZM7.12506 9.9375H10.8751C11.1826 9.9375 11.4376 9.6825 11.4376 9.375C11.4376 9.0675 11.1826 8.8125 10.8751 8.8125H7.12506C6.81756 8.8125 6.56256 9.0675 6.56256 9.375C6.56256 9.6825 6.81756 9.9375 7.12506 9.9375Z" fill="#A4A4A4"/>
</svg>

export { BellIcon, EthosiaLogo, EthosiaLogoMobile, MenuIcon, OpenInNewIcon, PlusIcon, SearchIcon, TrashIcon, UserIcon };

